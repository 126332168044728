<template>
  <main class="md:mx-auto">
    <div v-if="!isEdit" class="w-full">
      <div class="w-full flex md:flex-row flex-col items-start gap-3 md:items-center mb-9 md:justify-between">
        <h4 class="text-lg md:text-lg font-medium">Profile & KYC</h4>
        <div
          @click="hasData = true"
          class="text-white bg-deepGold rounded-lg flex flex-col items-center py-2 px-5 w-full md:w-64 space-y-4 cursor-pointer"
        >
          <div class="flex items-center w-full justify-between">
            <span>Level</span>

            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1.37695L5.66667 6.04362L1 10.7103"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div class="flex items-center w-full justify-between">
            <span class="font-bold">Tier {{ currentTier }}</span>

            <div style="background-color: #c0b280" class="p-2 rounded-full">Click to upgrade level</div>
          </div>
        </div>
      </div>

      <!-- <hr class="border-1 border-dark-100 my-4 md:my-6" /> -->
      <!-- <div
        class="easiGradient relative overflow-hidden w-full p-8 flex flex-col md:flex-row justify-center items-center gap-4"
      >
        <div
          class="rounded-full w-44 h-44 border-4 border-white overflow-hidden"
        >
          <img
            v-if="adminDetails && adminDetails.pfp"
            :src="adminDetails && adminDetails.pfp"
            class="object-cover object-center w-full h-full"
            :alt="adminDetails && adminDetails.firstName"
          />
          <div
            v-else
            :style="`background-color:${initials(getAdminName).color}`"
            class="p-2 w-full h-full text-white font-medium rounded-full flex items-center justify-center text-3xl md:text-5xl"
          >
            {{ initials(getAdminName).str }}
          </div>
        </div>
        <div class="flex flex-row md:flex-col gap-4">
      
          <UploadComponent :proPic="true" @fileUrl="updateProfilePic($event)" />
          <div
            @click="updateProfilePic(null)"
            class="cursor-pointer rounded-lg p-3 px-4 md:px-6 flex items-center gap-2 md:gap-4"
            :style="{
              color: 'rgba(255, 255, 255, 0.6)',
              border: '1px solid rgba(255, 255, 255, 0.5) ',
            }"
          >
            <i class="far fa-times-circle text-xl"></i>
            <span class="font-medium text-base">Cancel</span>
          </div>
        </div>

        <div
          class="absolute -right-24 bottom-4 w-56 opacity-25 pointer-events-none"
        >
          <img src="@/assets/icons/auth-illustration.svg" alt="" />
        </div>
      </div> -->

      <!-- <hr class="text-dark-400 my-4" /> -->

      <div class="flex flex-col gap-4">
        <div class="bg-landingGrey rounded-3xl p-4 flex items-end gap-4 text-xs md:text-sm">
          <easiTextCard type="text" title="First Name:" :value="adminDetails && adminDetails.firstName" />
          <easiTextCard type="text" title="Last Name:" :value="adminDetails && adminDetails.lastName" />
        </div>

        <div class="bg-landingGrey rounded-3xl p-4 flex items-end gap-4 text-xs md:text-sm">
          <easiTextCard
            type="text"
            title="Phone Number:"
            :value="(adminDetails && adminDetails.phoneNumber) || 'N/A'"
          />
          <easiTextCard type="text" title="Email:" :value="adminDetails && adminDetails.email" />
        </div>
      </div>

      <!-- <div class="w-full grid grid-cols-2 gap-4 mt-4">
        <div class="flex flex-col items-start col-span-2 md:col-span-1">
          <span class="text-sm md:text-base text-dark-600 font-medium"
            >First Name:</span
          >
          <span
            class="text-base md:text-lg text-dark-800 font-bold capitalize"
            >{{ adminDetails && adminDetails.firstName }}</span
          >
        </div>
        <div class="flex flex-col items-start col-span-2 md:col-span-1">
          <span class="text-sm md:text-base text-dark-600 font-medium"
            >Last Name:</span
          >
          <span
            class="text-base md:text-lg text-dark-800 font-bold capitalize"
            >{{ adminDetails && adminDetails.lastName }}</span
          >
        </div>

        <div class="w-full flex flex-col items-start col-span-2">
          <span class="text-sm md:text-base text-dark-600 font-medium"
            >Phone Number:</span
          >
          <span class="text-base md:text-lg text-dark-800 font-bold">{{
            adminDetails && adminDetails.phoneNumber
          }}</span>
        </div>
        <div class="w-full flex flex-col items-start col-span-2">
          <span class="text-sm md:text-base text-dark-600 font-medium"
            >Email:</span
          >
          <span
            class="text-base md:text-lg text-dark-800 font-bold lowercase"
            >{{ adminDetails && adminDetails.email }}</span
          >
        </div>
      </div> -->
    </div>

    <div v-else class="w-full px-6 py-10 relative">
      <span @click="isEdit = false" class="absolute top-4 right-4 cursor-pointer">
        <img src="@/assets/icons/close.svg" alt="" />
      </span>

      <h4 class="text-xl md:text-2xl font-medium">Edit Profile</h4>

      <hr class="text-dark-400 my-4" />

      <form @submit.prevent="updateProfile" autocomplete="on" class="flex flex-col gap-y-3">
        <easiTextInput
          placeholder="First Name"
          label="First Name"
          type="text"
          name="firstName"
          class="mt-1 mb-5"
          required
          v-model="args.firstName"
          :error="errorRules.firstName"
        ></easiTextInput>

        <easiTextInput
          placeholder="Last Name"
          label="Last Name"
          type="text"
          name="lastName"
          class="mt-1 mb-5"
          required
          v-model="args.lastName"
          :error="errorRules.lastName"
        ></easiTextInput>

        <div class="text-xs md:text-base mb-4">
          Please contact
          <a href="mailto:hi@myeazipay.com" class="text-secondary font-bold">support</a>
          to change your phone number or email address
        </div>

        <easiTextInput
          placeholder="Phone Number"
          label="Phone Number"
          type="tel"
          name="phoneNumber"
          readonly
          class="mt-1 mb-5"
          v-model="args.phoneNumber"
          :error="errorRules.phoneNumber"
        ></easiTextInput>

        <easiTextInput
          placeholder="Email"
          label="Email Address"
          type="email"
          name="email"
          readonly
          class="mt-1 mb-5"
          v-model="args.email"
          :error="errorRules.email"
          autocom
        ></easiTextInput>

        <div
          class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
        >
          <span
            @click="isEdit = false"
            class="w-full font-normal text-base cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 py-3"
            >Cancel</span
          >
          <div class="w-full md:w-full">
            <easiButton type="submit" :loading="loading" class="w-full"> Update </easiButton>
          </div>
        </div>
      </form>
    </div>

    <div class="card flex justify-content-center">
      <SideBar
        v-model:visible="hasData"
        position="right"
        class="md:w-96 w-11/12 h-full pb-4 lg:w-40rem"
        :showCloseIcon="false"
      >
        <div class="relative px-1 bg-white w-full h-screen">
          <div ref="headNav" class="flex justify-between items-center w-full mb-6">
            <button
              v-show="viewTier === true"
              @click="viewTier = false"
              class="focus:outline-none float-right bg-newAsh p-3 rounded-full cursor-pointer"
            >
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.0004 18.1686C12.8421 18.1686 12.6837 18.1103 12.5587 17.9853L7.12539 12.552C6.24206 11.6686 6.24206 10.2186 7.12539 9.33529L12.5587 3.90195C12.8004 3.66029 13.2004 3.66029 13.4421 3.90195C13.6837 4.14362 13.6837 4.54362 13.4421 4.78529L8.00872 10.2186C7.60872 10.6186 7.60872 11.2686 8.00872 11.6686L13.4421 17.102C13.6837 17.3436 13.6837 17.7436 13.4421 17.9853C13.3171 18.102 13.1587 18.1686 13.0004 18.1686Z"
                  fill="#292A29"
                />
              </svg>
            </button>
            <p class="text-dark-800 text-sm md:text-base font-bold text-center w-full justify-self-center">
              {{
                !viewTier
                  ? "KYC Levels"
                  : companyData && currentTier === 4 && companyData.registrationType === "PERSONAL"
                  ? "Upgrade transaction limit"
                  : companyData && currentTier === 4 && companyData.registrationType === "COMPANY"
                  ? "Upgrade transaction limit"
                  : `Upgrade to Tier ${currentTier + 1}`
              }}
            </p>
            <button
              @click="hasData = false"
              class="focus:outline-none float-right bg-newAsh p-3 rounded-full cursor-pointer"
            >
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.05806 5.50142C5.30214 5.25734 5.69786 5.25734 5.94194 5.50142L10.5 10.0595L15.0581 5.50142C15.3021 5.25734 15.6979 5.25734 15.9419 5.50142C16.186 5.7455 16.186 6.14122 15.9419 6.3853L11.3839 10.9434L15.9419 15.5014C16.186 15.7455 16.186 16.1412 15.9419 16.3853C15.6979 16.6294 15.3021 16.6294 15.0581 16.3853L10.5 11.8272L5.94194 16.3853C5.69786 16.6294 5.30214 16.6294 5.05806 16.3853C4.81398 16.1412 4.81398 15.7455 5.05806 15.5014L9.61612 10.9434L5.05806 6.3853C4.81398 6.14122 4.81398 5.7455 5.05806 5.50142Z"
                  fill="#707170"
                />
              </svg>
            </button>
          </div>

          <div v-show="viewTier === false" class="w-full">
            <TierCard />
          </div>

          <div v-show="viewTier" class="w-full">
            <Tier1 v-show="currentTier === 0" @saved="updateSuccess = true" />
            <Tier2 v-show="currentTier === 1" @saved="updateSuccess = true" />
            <Tier3 v-show="currentTier === 2" @saved="updateSuccess = true" @dismiss="dismissModal" />
            <Tier4
              v-show="
                (currentTier === 4 && companyData.registrationType === 'PERSONAL') ||
                (currentTier === 4 && companyData.registrationType === 'COMPANY')
              "
              :amount="Number(amount)"
              @saved="updateSuccess = true"
            />
            <companyTier
              v-show="currentTier === 3 && companyData.registrationType === 'COMPANY'"
              @saved="updateSuccess = true"
            />
          </div>

          <div
            v-show="viewTier === false"
            class="sticky w-full -bottom-8 right-0 left-0 bg-white shadow-lg py-4 inset-b-0 inset-x-0 flex mt-5 justify-center"
          >
            <!-- <easiButton
              class="w-full"
              @click="inputAmount = true"
              v-if="companyData.registrationType === 'PERSONAL' && currentTier === 4"
            >
              <span class="text-xs"> Upgrade transaction limit</span></easiButton
            >

            <easiButton
              class="w-full"
              @click="inputAmount = true"
              v-else-if="companyData.registrationType === 'COMPANY' && currentTier === 4"
            >
              <span class="text-xs">Upgrade transaction limit</span></easiButton
            > -->

            <!-- <BottomDialog
              v-if="
                (companyData.registrationType === 'PERSONAL' && currentTier === 4) ||
                (companyData.registrationType === 'COMPANY' && currentTier === 4)
              "
              :text="
                companyData.registrationType === 'PERSONAL' && currentTier === 4
                  ? 'Upgrade to transfer limit to 20 million'
                  : companyData.registrationType === 'COMPANY' && currentTier === 4
                  ? 'Upgrade transfer limit to 100 million'
                  : ''
              "
              @action="switchView"
              :show="inputAmount"
            /> -->
            <easiButton :block="true" class="w-full" v-if="currentTier !== 4" @click="switchView">
              <span class="text-xs w-full"> {{ `Upgrade to Tier ${currentTier + 1}` }}</span></easiButton
            >
          </div>
        </div>

        <SuccessScreen v-if="updateSuccess" @dismiss="dismissModal" />
      </SideBar>
    </div>

    <div class="relative card flex justify-content-center">
      <SideBar
        v-model:visible="inputAmount"
        position="bottom"
        class="right-0 md:w-96 w-11/12 h-full pb-4 lg:w-20rem"
        :showCloseIcon="false"
        style="height: auto; position: absolute; right: 0"
      >
        <main class="w-full h-full">
          <div class="w-full flex items-center justify-between pb-4 relative border-b border-dark-550">
            <h2 style="font-size: 18px" class="text-heading font-bold">Upgrade Transaction Limit</h2>

            <div @click="inputAmount = false" style="padding: 14px" class="bg-dark-550 rounded-full">
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.05806 0.558058C1.30214 0.313981 1.69786 0.313981 1.94194 0.558058L6.5 5.11612L11.0581 0.558059C11.3021 0.313981 11.6979 0.313981 11.9419 0.558059C12.186 0.802137 12.186 1.19786 11.9419 1.44194L7.38388 6L11.9419 10.5581C12.186 10.8021 12.186 11.1979 11.9419 11.4419C11.6979 11.686 11.3021 11.686 11.0581 11.4419L6.5 6.88388L1.94194 11.4419C1.69786 11.686 1.30214 11.686 1.05806 11.4419C0.813981 11.1979 0.813981 10.8021 1.05806 10.5581L5.61612 6L1.05806 1.44194C0.813981 1.19786 0.813981 0.802136 1.05806 0.558058Z"
                  fill="#707170"
                />
              </svg>
            </div>
          </div>

          <div class="mt-3 w-full gap-x-4 gap-y-3">
            <easiTextInput
              :hint="
                companyData && companyData.registrationType === 'PERSONAL' && currentTier === 4
                  ? 'Maximum amount is <strong>20,000,000</strong> (Twenty million naira)'
                  : 'Maximum amount is <strong>100,000,000</strong> (One hundred million naira)'
              "
              :format="true"
              :error="error"
              class="w-full"
              v-model="amount"
              placeholder="Enter transaction limit"
              label="Enter transaction limit"
            ></easiTextInput>

            <easiButton @click="switchView" class="w-full mt-9">Submit</easiButton>
          </div>
        </main>
      </SideBar>
    </div>

    <!-- <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span> Profile updated successfully</span>
      </template>
    </easiSuccess> -->

    <easiRoundLoader v-if="loading" />

    <div
      @click="initDojah(widgetMatch[currentTier])"
      ref="d_button"
      class="dojah-button hidden bg-primary rounded-full p-2 text-white"
    >
      Click me
    </div>
    <button
      @click="initDojah(widgetMatch[currentTier])"
      ref="d_button1"
      class="dojah-button1 hidden bg-primary rounded-full p-2 text-white"
    >
      Click me1
    </button>
    <button
      @click="initDojah(widgetMatch[currentTier])"
      ref="d_button2"
      class="hidden dojah-button2 bg-primary rounded-full p-2 text-white"
    >
      Click me
    </button>
    <button
      @click="initDojah(widgetMatch[currentTier])"
      ref="d_button3"
      class="hidden dojah-button3 bg-primary rounded-full p-2 text-white"
    >
      Click me
    </button>
  </main>
</template>

<script setup>
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import TierCard from "@/components/Settings/Profile/KYC/TierCard.vue";
import Tier1 from "@/components/Settings/Profile/KYC/Tiers/Tier1.vue";
import Tier2 from "@/components/Settings/Profile/KYC/Tiers/Tier2.vue";
import Tier3 from "@/components/Settings/Profile/KYC/Tiers/Tier3.vue";
import Tier4 from "@/components/Settings/Profile/KYC/Tiers/Tier4.vue";
import CompanyTier from "@/components/Settings/Profile/KYC/Tiers/CompanyTier.vue";
import SuccessScreen from "./SuccessScreen.vue";
import BottomDialog from "@/components/global/BottomDialog.vue";
import UploadComponent from "@/components/global/UploadComponent";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const { processDate, validatePhone, validateEmail, uploadFileToServer } = helperFunctions;

const adminDetails = computed(() => store.getCompanyAdmin && store.getCompanyAdmin.companyAdmin);
const companyData = computed(() => store.getCompanyDetails);

const hasData = ref(false);
const viewTier = ref(false);
const inputAmount = ref(false);
const loading = ref(false);
const updateSuccess = ref(false);
const isEdit = ref(false);
const uploadFile = ref(null);
const headNav = ref(null);

function moveCenter(e) {
  const element = headNav.value;
  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });
}

const error = computed(() => {
  if (companyData.value && companyData.value.registrationType === "PERSONAL" && currentTier.value !== 3) {
    return false;
  }
  if (companyData.value && companyData.value.registrationType === "COMPANY" && currentTier.value !== 4) {
    return false;
  }
  if (companyData.value && companyData.value.registrationType === "PERSONAL" && Number(amount.value) <= 20000000) {
    return false;
  } else if (
    companyData.value &&
    companyData.value.registrationType === "COMPANY" &&
    Number(amount.value) <= 100000000
  ) {
    return false;
  } else {
    return "The amount entered cannot exceed your specified limit";
  }
});
const amount = ref("0");
const d_button = ref(null);
const d_button1 = ref(null);
const d_button2 = ref(null);
const d_button3 = ref(null);

function dismissModal() {
  hasData.value = false;
  viewTier.value = false;
}

const errorRules = ref({
  firstName: "",
  lastName: "",
  // phoneNumber: "",
  // email: "",
});

const args = reactive({
  firstName: "",
  lastName: "",
  pfp: "",
  // email: "",
  // phoneNumber: "",
});

function validate() {
  if (args.firstName.trim() == "") {
    errorRules.firstName = "Please enter first name";
    return false;
  } else if (args.lastName.trim() == "") {
    errorRules.firstName = "Please enter first name";
    return false;
  } else {
    errorRules.firstName = false;
    errorRules.lastName = false;

    return true;
  }
}

function assignValues() {
  const keys = ["firstName", "lastName", "email", "phoneNumber", "pfp"];
  for (let i = 0; i < keys.length; i++) {
    if (adminDetails.value && adminDetails.value[keys[i]]) {
      args[keys[i]] = adminDetails.value[keys[i]];
    }
  }
}

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const initials = computed(() => {
  return (name) => {
    if (name && name.length) {
      let color;
      // Get color

      const arr = name.split(" ");
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
      const initialOne = String(arr[0]).charAt(0);
      const initialTwo = String(arr[1]).charAt(0);
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) + Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = getColor(num);
      const str = `${arr[0].charAt(0).toUpperCase()}${arr[1].charAt(0).toUpperCase()}`;
      return { str, color };
    } else {
      return { str: "AA", color: "#189F00" };
    }
  };
});

const getAdminName = computed(() => {
  let name = "";

  if (adminDetails.value) {
    name = `${adminDetails.value && adminDetails.value.firstName} ${adminDetails.value && adminDetails.value.lastName}`;
  }
  return name;
});

const updateProfile = async () => {
  if (validate()) {
    loading.value = true;

    // log(args);
    const payload = {
      firstName: args.firstName,
      lastName: args.lastName,
      pfp: args.pfp,
    };
    try {
      let res = await mutate({
        endpoint: "UpdateAdminProfile",
        data: { input: payload },
        service: "SETTINGS",
      });

      // log(res);
      if (res.success) {
        loading.value = false;
        isEdit.value = false;
        updateSuccess.value = true;
        setTimeout(() => {
          window.location.reload();
        }, 500);
        // toast.success(res.message);

        await queryCompanyAdmin();
        await assignValues();
      } else {
        loading.value = false;
      }
    } catch (e) {
      loading.value = false;
      toast.error(e.message);
      // log(e);
    }
  }
};

async function updateProfilePic(e) {
  loading.value = true;

  const img = await uploadFileToServer(e);

  const payload = {
    firstName: args.firstName,
    lastName: args.lastName,
    pfp: img,
  };

  try {
    let res = await mutate({
      endpoint: "UpdateAdminProfile",
      data: { input: payload },
      service: "SETTINGS",
    });
    if (res.success) {
      loading.value = false;
      // console.log(res);

      await queryCompanyAdmin();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
      }, 500);
    } else {
      loading.value = false;
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}

const queryCompanyAdmin = async () => {
  await query({
    endpoint: "FetchCompanyAdmin",
    service: "SETTINGS",
    storeKey: "companyAdmin",
  });

  console.log("Company Admin Details");
  // log(adminDetails);
};

const currentTier = computed(() => {
  if (companyData.value && companyData.value.tier) {
    return Number(companyData.value.tier);
  }
  return 0;
});
const queryCompany = async () => {
  try {
    await query({
      endpoint: "FetchCompaniesByAdminId",
      service: "SETTINGS",
      storeKey: "companyDetails",
    });
  } catch (e) {
    console.log(e);
  }
};
queryCompany();
queryCompanyAdmin();
assignValues();

onMounted(async () => {
  await queryCompany();
});

const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
};

function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}

const showDojaModal = () => {
  if (currentTier.value === 0 && d_button.value) {
    d_button.value.click();
  }
  if (currentTier.value === 1 && d_button1.value) {
    d_button1.value.click();
  }
  if (currentTier.value === 2 && d_button2.value) {
    d_button2.value.click();
  }
  if (currentTier.value === 3 && d_button3.value) {
    d_button3.value.click();
  }
};
function switchView() {
  if (currentTier.value === 4 && !error.value) {
    inputAmount.value = false;
    viewTier.value = !viewTier.value;
    moveCenter();
  } else {
    showDojaModal();
  }
}
const widgetMatch = [
  "663141ac79a6430040b413a3",
  "6631423caace7a0040a15d54",
  "6631427caace7a0040a15d8c",
  "663142d7ee631f004030da11",
];

function initDojah(widget_id) {
  const email = (adminDetails.value && adminDetails.value.email) || "";
  // console.log(e.target.matches(element));
  // if (!e.target.matches(element)) return;
  const app_id = process.env.VUE_APP_DOJAH_APP_KEY;
  const p_key = process.env.VUE_APP_DOJAH_P_KEY;

  const options = {
    app_id, //your app_id here
    p_key, //your production public key here
    type: "custom",
    user_data: {
      first_name: (adminDetails.value && adminDetails.value.firstName) || "", //optional
      last_name: (adminDetails.value && adminDetails.value.lastName) || "", //optional
      // dob: {$dob}, //YYYY-MM-DD Optional
      // residence_country: 'NG', //optional
      email, //optional
    },

    metadata: {
      first_name: (adminDetails.value && adminDetails.value.firstName) || "", //optional
      last_name: (adminDetails.value && adminDetails.value.lastName) || "", //optional
      user_id: (adminDetails.value && adminDetails.value._id) || "",
      company_id: (companyData.value && companyData.value._id) || "",
      email,
    },

    config: {
      widget_id:
        currentTier.value === 0 && companyData.value && companyData.value.registrationType === "COMPANY"
          ? "66b112b6645a7b004025bfe8" //Tier 1 widget with CAC verification for business accounts
          : widget_id, //this is generated from easyonboard
    },
    onSuccess: function (response) {
      console.log("Success", response);
      if (response.status) {
        // upgradeTier();
        updateSuccess.value = true;
      }
    },
    onError: function (err) {
      console.log("Error", err);
    },
    onClose: function () {
      console.log("Widget closed");
    },
  };
  // console.log(options);
  const connect = new Connect(options);
  // const button = document.querySelector(element);
  // if (!button) return;
  connect.setup();
  connect.open();
}
</script>

<style>
.color {
  color: #b9bbc07d;
  color: #e0e0e08b;
}
</style>
